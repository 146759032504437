.portal {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.login {
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  height: 100vh;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 2em;

  .loginLogo {
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 300px;
    }

    span {
      font-size: 36px;
      font-weight: bold;
      margin-inline-start: 0.8em;
      margin-inline-end: 0.8em;
    }
  }

  .loginForm {
    width: 100%;
    display: flex;
    flex-direction: column;

    .formField {
      display: block;
      margin-bottom: 0.5em;
      font-weight: 600;
    }

    .loginPinLabel {
      display: block;
      margin-bottom: 0.5em;
      font-weight: 600;
    }

    .loginPinInput {
      background: #f9fafb;
      box-sizing: border-box;
      border: 1px solid #c0c4c9;
      border-radius: 0.25rem;
      font-size: 18px;
      width: 100%;
      padding: 10px;
    }

    .loginButton {
      margin-top: 1rem;
      background: #74d76b;
      color: white;
      font-size: 18px;
      font-weight: bold;
      padding: 10px;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
    }
  }
}