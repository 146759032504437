@use "../../styles//animation.scss";

$banner-animation-duration: 2s;

.title-bar {
  // position: sticky;
  background-color: #fff;
  border-bottom: 1px solid #dcdce4;
  top: 0;
  font-weight: bolder;
  padding: 0.5em 0.7em;

  .title-options-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left-group {
      display: flex;
      align-items: center;

      * {
        margin-right: 0.5em;
      }
    }

    .title-right-group {
      display: flex;
      align-items: center;

      button {
        background-color: unset;
        border: none;
        cursor: pointer;
        width: 40px;

        img {
          width: 100%;
        }
      }

      .user-logo--disabled {
        opacity: 0.5;
        cursor: default;
      }

      * {
        margin-left: 0.5em;
      }
    }
  }
}
