:root {
  --background-color: #f7f7f8;
  --company-identity-color: #02b98c;
  --svg-filter: invert(44%) sepia(94%) saturate(1397%) hue-rotate(132deg) brightness(97%) contrast(98%);
  --aia-eb-bg-image: url("../assets/aiahk-eb-background.png");
  --aia-my-bg-image: url("../assets/aia-my-background.png");
  --aia-axa-bg-image: url("../assets/axa-background.png");
  --aia-agency-bg-image: url("../assets/aia-agency-background.png");
  --bea-bg-image: url("../assets/customer-profile-bg.png");
  --sunlife-bg-image: url("../assets/sunlife-background.png");
  --smartone-bg-image: url('../assets/smartone-background.png');
  --esg-bg-image: url('../assets/esg-background.jpeg');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
