.portal-body {
  text-align: center;
  margin: auto;
  flex: 1;
  padding: 2em 0;
  background-image: var(--background-image);
  background-size: cover;
  // background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
}

.portal-body__welcome {
  padding: 0 0.3em;
  padding-bottom: 1em;
}

.portal-body__form {
  width: 350px;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 280px;
  }
}

.portal-body__input {
  display: block;
  background-color: "#FFF";
  border: 1px solid #b3b1a8;
  padding: 1em;
  min-width: 250px;
  margin-top: 1.5em;
  width: 100%;
}

.portal-body__input:focus {
  border: 1px solid var(--company-identity-color);
}

.portal-body__link {
  color: #554344;
  text-decoration: underline;
}

.portal-body__button {
  margin: 3em auto;
  background-color: var(--company-identity-color);
  outline: none;
  border: 0;
  color: #fff;
  font-weight: bold;
  min-width: 250px;
  padding: 1em 0;
  display: block;
  width: 100%;
  margin-bottom: 3.5em;
}

.portal-body__alternatives {
  display: flex;
  margin: 2.5em auto;
  justify-content: center;
  font-weight: bold;
  column-gap: 5em;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 1em;
  }
}

.alternatives__option {
  color: #22a8da;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.portal-body__disclaimer {
  margin: auto;
  width: 70%;
  color: #554344;
  font-size: 0.9rem;

  @media screen and (max-width: 1024px) {
    width: 80%;
  }
}
