.floating-chat-bot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  direction: rtl;
}

.floating-chat-bot__avatar {
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-image: url("../../assets/avatar.svg");
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 8px 3px #ffffff15;
  transform: translateX(150%);
  animation: slideToLeft forwards 450ms ease-in-out;
}

.floating-chat-bot__chat-container {
  position: relative;
  direction: ltr;
  height: 800px;
  max-height: 80vh;
  width: 900px;
  max-width: 85vw;
  overflow: auto;
  margin-bottom: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 2px 2px #00000030;
  animation: fadeIn normal 250ms ease-in-out;
  background-color: var(--background-color);
}

.floating-chat-bot__chat-container--hide {
  display: none;
}

.floating-chat-bot__message {
  cursor: pointer;
  position: absolute;
  direction: ltr;
  background-color: var(--background-color);
  color: #000000;
  margin-bottom: 0.75em;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  width: fit-content;
  min-width: 280px;
  width: 55vw;
  max-width: 450px;
  bottom: 35px;
  right: 50px;
  box-shadow: 0px 3px 7px 4px #00000035;
  transition:
    transform 400ms ease-in-out,
    opacity 300ms ease-in-out;
}

.floating-chat-bot__message-header {
  background-color: var(--company-identity-color);
  color: #fff;
  padding: 0.8em;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.floating-chat-bot__close {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0px 4px 5px 4px #00000030;
  right: -10px;
  top: -10px;

  & > img {
    width: 14px;
  }
}

.floating-chat-bot__message-paragraph {
  padding: 0.2em 0.8em;
  font-size: 0.9rem;
}

.floating-chat-bot__message--hide {
  transform: translateY(250%);
  opacity: 0;
}

.floating-chat-bot__message--show {
  transform: translateY(0);
  opacity: 1;
}

@supports (-webkit-touch-callout: none) {
  .floating-chat-bot__chat-container {
    max-height: 80svh;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideToTop {
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(150%);
  }

  to {
    transform: translateX(0);
  }
}
